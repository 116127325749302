import logo from "../static/images/logo.png";
import HamburgerMenuButton from "./Hamburger";

export default function TopNav() {
	// TODO: Image height prop
	return (
		<div className={"topnav"}>
			<img style={{ height: 136 }} alt="logo" src={logo} height={136} />
			<HamburgerMenuButton />
		</div>
	);
}
