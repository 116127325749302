import image1 from "../static/images/AbsoluteCard/image1.jpg";
import image2 from "../static/images/AbsoluteCard/image2.jpg";
import image3 from "../static/images/AbsoluteCard/image3.jpg";
import image4 from "../static/images/AbsoluteCard/image4.jpg";

export default function AbsoluteCard() {
	return (
		<div
			style={{
				minHeight: "120vh",
				maxHeight: "120vh",
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			<div
				style={{
					minHeight: "55%",
					maxHeight: "55%",
					display: "flex",
					flexDirection: "row",
					width: "100%",
				}}
			>
				<div
					style={{
						minWidth: "50%",
						maxWidth: "50%",
						height: "100%",
						alignSelf: "start",
						display: "flex",
						alignItems: "start",
						justifyContent: "center",
					}}
				>
					<img
						style={{
							maxHeight: "75%",
							paddingLeft: 30,
							paddingRight: 5,
							paddingTop: 20,
						}}
						src={image1}
						alt="Graphic design"
					/>
				</div>
				<div
					style={{
						minWidth: "50%",
						maxWidth: "50%",
						height: "100%",
						alignSelf: "end",
						display: "flex",
						alignItems: "end",
						justifyContent: "start",
					}}
				>
					<img
						style={{
							maxHeight: "75%",
							paddingLeft: 15,
							paddingRight: 20,
							paddingBottom: 20,
						}}
						src={image2}
						alt="Graphic design"
					/>
				</div>
			</div>
			<div
				style={{
					minHeight: "45%",
					maxHeight: "45%",
					display: "flex",
					flexDirection: "row",
					width: "100%",
				}}
			>
				<div
					style={{
						minWidth: "60%",
						maxWidth: "60%",
						height: "100%",
						alignSelf: "start",
						display: "flex",
						alignItems: "start",
						justifyContent: "center",
					}}
				>
					<img
						style={{
							maxHeight: "60%",
							paddingLeft: 5,
							paddingRight: 5,
							paddingTop: 10,
						}}
						src={image3}
						alt="Graphic design"
					/>
				</div>
				<div
					style={{
						minWidth: "40%",
						maxWidth: "40%",
						height: "100%",
						alignSelf: "end",
						display: "flex",
						alignItems: "end",
						justifyContent: "start",
					}}
				>
					<img
						style={{
							maxHeight: "80%",
							paddingLeft: 10,
							paddingRight: 10,
							paddingBottom: 20,
						}}
						src={image4}
						alt="Graphic design"
					/>
				</div>
			</div>
		</div>
	);
}
