import { type ReactNode } from "react";

interface CardContentProps {
	order: number;
	children: ReactNode;
	hasMinHeight?: boolean;
}

export default function CardContent(props: CardContentProps) {
	const { order, children, hasMinHeight } = props;
	const cssClass = order === 1 ? "cardContent_1" : "cardContent";
	const minHeightClass = order === 1 ? "" : hasMinHeight ? "minHeight" : "";
	return <div className={`${cssClass} ${minHeightClass}`}>{children}</div>;
}
