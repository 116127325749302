/**
 *              TODOs:
 * fix button active behavior on mobile
 * fix fonts on mobile
 * fix scrolling when overlay is active
 * */

/* eslint-disable max-len */
import TopNav from "./Navigation/TopNav";
import MainContentContainer from "./Layout/Main";
import Card from "./Layout/Card";
import MenuOverlay from "./Navigation/MenuOverlay";
import LandingCard from "./CardContents/LandingCard";
import AbsoluteCard from "./CardContents/AbsV2";
import StandardContentCard from "./CardContents/StandardContentCard";
import pagedata from "./pagedata.json";
import { ReactNode } from "react";
import "./App.css";

interface CardDetail {
	variant: string;
	order: number;
	bgUrl?: string;
	bgColor?: string;
	date?: string;
	themeColors?: {
		titleColor: string;
		subtitleColor: string;
		roleColor: string;
		roleContentColor: string;
		roleBulletColor: string;
		descriptionColor: string;
	};
	subtitle?: string;
	title?: string;
	role?: string;
	description?: string[];
	images?: string[];
	hasMinHeight?: boolean;
}

const Cards = (cardDetails: CardDetail[]) => {
	return (
		<>
			{cardDetails.map((card) => {
				let child: ReactNode;
				switch (card.variant) {
					case "LandingCard":
						child = <LandingCard />;
						break;
					case "AbsoluteCard":
						child = <AbsoluteCard />;
						break;
					case "Standard":
						child = (
							<StandardContentCard
								date={card.date ?? ""}
								themeColors={
									card.themeColors ?? {
										titleColor: "",
										subtitleColor: "",
										roleColor: "",
										roleContentColor: "",
										roleBulletColor: "",
										descriptionColor: "",
									}
								}
								subtitle={card.subtitle ?? ""}
								title={card.title ?? ""}
								role={card.role ?? ""}
								description={card.description ?? [""]}
								images={card.images ?? [""]}
							/>
						);
						break;
					default:
						break;
				}
				return (
					<Card
						hasMinHeight={card.hasMinHeight}
						order={card.order}
						key={card.order}
						bgUrl={card.bgUrl}
						bgColor={card.bgColor}
					>
						{child}
					</Card>
				);
			})}
		</>
	);
};

export default function App() {
	return (
		<div>
			<MenuOverlay />
			<TopNav />
			<MainContentContainer>{Cards(pagedata)}</MainContentContainer>
		</div>
	);
}
