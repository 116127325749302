import { ReactNode } from "react";

export default function ContentItem(props: {
	index: number;
	children: ReactNode;
}) {
	const { index, children } = props;
	return (
		<div className={`content-item carouselImage`} data-index={index}>
			{children}
		</div>
	);
}
