export default function Line(props: { color: string }) {
	const { color } = props;
	return (
		<div
			className={`font-mono lineDelimiter flexColumnItem`}
			style={{ color }}
		>
			LINELINELINELINELINELINELINE
		</div>
	);
}
