export default function Role(props: {
	role: string;
	roleColor: string;
	roleContentColor: string;
	roleBulletColor: string;
}) {
	const { roleColor, roleBulletColor, role, roleContentColor } = props;
	return role === "" ? (
		<></>
	) : (
		<div className={`flexColumnItem roleCol`}>
			<div
				style={{ backgroundColor: roleBulletColor }}
				className={"roleBullet"}
			></div>
			<div style={{ color: roleColor }} className={`role font-mono`}>
				ROLE :{" "}
			</div>
			<div
				style={{ color: roleContentColor }}
				className={"font-mono roleDesc"}
			>
				{role}
			</div>
		</div>
	);
}
