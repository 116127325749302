export default function LandingCard() {
	return (
		<div className={"landingContent"}>
			<p>GABRIEL</p>
			<p>COYNE</p>
			<p>MARTINEZ</p>
			<p className={`landingTitle font-content`}>
				Graphic Designer | Marketing Coordinator
			</p>
		</div>
	);
}
