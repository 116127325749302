import { Dispatch, SetStateAction } from "react";

interface IndexDotProps {
	index: number;
	elementToScroll: HTMLElement | Element | null;
	isHighlighted: boolean;
	setCurrentIndex: Dispatch<SetStateAction<number>>;
}

export default function IndexDot(props: IndexDotProps) {
	const { elementToScroll, isHighlighted } = props;
	const classes = isHighlighted ? "indexDotHighlighted" : "indexDot";
	const clkFn = () => {
		elementToScroll?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "center",
		});
	};
	return <div onClick={clkFn} className={classes}></div>;
}
