export default function Subtitle(props: { subtitle: string; color: string }) {
	const { subtitle, color } = props;
	return (
		<div
			className={`subtitle flexColumnItem font-content`}
			style={{ color }}
		>
			{subtitle}
		</div>
	);
}
