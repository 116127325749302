import { useState, useEffect } from "react";

export default function HamburgerMenuButton() {
	const [clicked, setClicked] = useState(false);
	const [firstClick, setFirstClick] = useState(true);
	const [overlay, setOverlay] = useState<HTMLElement | null>(null);
	const [overlayWrapper, setWrapper] = useState<HTMLElement | null>(null);
	const [body, setBody] = useState<HTMLElement | null>(null);
	const [menuLineTop, setMenuLineTop] = useState<HTMLElement | null>(null);
	const [menuLineMid, setMenuLineMid] = useState<HTMLElement | null>(null);
	const [menuLineBot, setMenuLineBot] = useState<HTMLElement | null>(null);
	const [menuContainer, setMenuContainer] = useState<HTMLElement | null>(
		null
	);

	const onClk = () => {
		setClicked(true);
		if (overlay && overlayWrapper) {
			body?.classList.toggle("no-overflow");
			overlayWrapper.classList.toggle("overlayShow");

			menuLineTop?.classList.toggle("topLineX");
			menuLineMid?.classList.toggle("midLineX");
			menuLineBot?.classList.toggle("bottomLineX");
			menuContainer?.classList.toggle("hamburgerBox");
			menuContainer?.classList.toggle("toggled");
			menuContainer?.classList.toggle("hamburgerBoxNoHover");

			if (!firstClick) {
				menuLineTop?.classList.toggle("topLineBurg");
				menuLineMid?.classList.toggle("midLineBurg");
				menuLineBot?.classList.toggle("bottomLineBurg");
			}
			if (firstClick) {
				setFirstClick(false);
			}
		}
	};

	useEffect(() => {
		if (clicked === true) {
			setTimeout(() => {
				menuContainer?.classList.toggle("toggled");
				setClicked(false);
			}, 150);
		}
	}, [clicked, menuContainer?.classList]);

	useEffect(() => {
		setOverlay(document.getElementById("menu_overlay"));
		setWrapper(document.getElementById("slide_wrapper"));
		setBody(document.getElementById("body"));
		setMenuLineTop(document.getElementById("top-line"));
		setMenuLineMid(document.getElementById("mid-line"));
		setMenuLineBot(document.getElementById("bot-line"));
		setMenuContainer(document.getElementById("menu-container"));
	}, []);

	return (
		<div className={"hamburgerContainer"}>
			<div className={"burgerBackground"}>
				<button
					id="menu_container"
					onClick={onClk}
					className={"hamburgerBox"}
					type="button"
				>
					<div id="top-line" />
					<div id="mid-line" className={"hamburgerMid"} />
					<div id="bot-line" />
				</button>
			</div>
		</div>
	);
}
