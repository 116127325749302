import UlPoint from "./UlPoint";

export default function MenuOverlay() {
	return (
		<div id="slide_wrapper" className={"slideWrapperWrapper"}>
			<div className={"slideWrapper"}>
				<div id={"menu_overlay"} className={"slide"}>
					<div className={"contentWidth"}>
						<div className={"pointContainer"}>
							<UlPoint />
							<a
								href="https://www.instagram.com/gpcmdesign/"
								className={`link font-content`}
								target="_blank"
								rel="noreferrer"
							>
								Instagram
							</a>
						</div>
						<div className={"pointContainer"}>
							<UlPoint />
							<a
								href="https://www.linkedin.com/in/gabrielcoynemartinez/"
								className={`link font-content`}
								target="_blank"
								rel="noreferrer"
							>
								LinkedIn
							</a>
						</div>
						<div className={"pointContainer"}>
							<UlPoint />
							<a
								href="mailto:gpcoyne99@gmail.com"
								className={`link font-content`}
								target="_blank"
								rel="noreferrer"
							>
								Email
							</a>
						</div>
					</div>
					<div className="font-content">
						As a highly motivated and creative individual, I am
						always seeking new challenges and opportunities to
						develop my skills in the dynamic world of marketing. I
						am passionate about leveraging my strengths in
						communication, collaboration, and hard work to deliver
						exceptional results for clients and exceed their
						expectations. With a background as an athlete, I have
						honed my ability to work as a team player, handle
						criticism constructively, and stay focused on achieving
						success. Whether developing innovative marketing
						campaigns or collaborating with colleagues to achieve
						shared goals, I am committed to pursuing opportunities
						that will enable me to sharpen my skills and continue to
						grow as a marketing professional.
					</div>
				</div>
			</div>
		</div>
	);
}
