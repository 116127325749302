import IndexDot from "./IndexDot";
import { Dispatch, SetStateAction } from "react";

interface PaginationProps {
	currentIndex: number;
	length: number;
	elementMap: { [key: number]: Element | HTMLElement } | null;
	setCurrentIndex: Dispatch<SetStateAction<number>>;
}

export default function Pagination(props: PaginationProps) {
	const { currentIndex, length, elementMap, setCurrentIndex } = props;
	const indexDots = [];
	for (let i = 0; i < length; i++) {
		const element = elementMap ? elementMap[i] : null;
		indexDots.push(
			<IndexDot
				setCurrentIndex={setCurrentIndex}
				key={i}
				index={i}
				elementToScroll={element}
				isHighlighted={currentIndex === i}
			/>
		);
	}
	return <>{indexDots}</>;
}
