import { ReactNode, useState, useEffect, useRef } from "react";
import Pagination from "./Pagination";
import ContentItem from "./ContentItem";

export default function Carousel(props: { children: ReactNode[] }) {
	const { children } = props;

	const [currentIndex, setCurrentIndex] = useState(0);
	const [elementMap, setElements] = useState<{
		[key: number]: Element | HTMLElement;
	} | null>(null);
	const [currentElement, setCurrentElement] = useState<
		Element | HTMLElement | null
	>(null);
	const length = children?.length || 0;
	const [load, setLoad] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const observerRef = useRef<IntersectionObserver | null>(null);

	const observerCallback = (entries: IntersectionObserverEntry[]) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setCurrentElement(entry?.target);
			}
		});
	};
	useEffect(() => {
		setTimeout(() => setLoad(true), 100);

		const root = containerRef.current;
		const items = root?.querySelectorAll(".content-item");
		const options = {
			root,
			rootMargin: "0px",
			threshold: 0.6,
		};
		const observer =
			observerRef.current ??
			new IntersectionObserver(observerCallback, options);
		const elements: { [key: number]: HTMLElement } = {};
		items?.forEach((el) => {
			if (el instanceof HTMLElement && el?.dataset.index) {
				observer.observe(el);
				elements[parseInt(el?.dataset.index)] = el;
			}
		});
		setElements(elements);
		observerRef.current = observer;
	}, [load]);

	useEffect(() => {
		if (
			currentElement instanceof HTMLElement &&
			currentElement?.dataset.index
		) {
			setCurrentIndex(parseInt(currentElement?.dataset.index));
		}
	}, [currentElement]);

	return load ? (
		<div
			ref={containerRef}
			style={{
				maxWidth: 1200,
				marginLeft: "auto",
				marginRight: "auto",
				marginTop: 35,
			}}
		>
			<div className="carouselContainer">
				<div className="carouselWrapper">
					<div className="carouselContentWrapper">
						<div className="carouselContent">
							{children.map((value, index) => {
								return (
									<ContentItem key={index} index={index}>
										{value}
									</ContentItem>
								);
							})}
						</div>
					</div>
				</div>
				<div className="carouselPaginationWrapper">
					<Pagination
						elementMap={elementMap}
						currentIndex={currentIndex}
						length={length}
						setCurrentIndex={setCurrentIndex}
					/>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
}
