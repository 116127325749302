import DateHeader from "./Common/DateHeader";
import Title from "./Common/Title";
import Line from "./Common/Line";
import Subtitle from "./Common/SubTitle";
import Role from "./Common/Role";
import StandardParagraph from "./Common/StandardParagraph";
import Carousel from "./Common/Carousel/Carousel";

export interface ThemeColors {
	titleColor: string;
	subtitleColor: string;
	roleColor: string;
	roleContentColor: string;
	roleBulletColor: string;
	descriptionColor: string;
}

export interface CardDetails {
	date: string;
	title: string;
	subtitle: string;
	themeColors: ThemeColors;
	images: string[];
	role: string;
	description: string[];
}

export default function StandardContentCard(props: CardDetails) {
	const { date, title, subtitle, themeColors, images, role, description } =
		props;

	return (
		<div className={"standardContent"}>
			<DateHeader date={date} color={themeColors.titleColor} />
			<Title title={title} color={themeColors.titleColor} />
			<Line color={themeColors.titleColor} />
			<Subtitle color={themeColors.subtitleColor} subtitle={subtitle} />
			<Carousel>
				{images.map((value, index) => {
					return (
						<img
							key={index}
							className={"carouselImage"}
							src={value}
							alt="art"
						/>
					);
				})}
			</Carousel>
			<Role
				role={role}
				roleColor={themeColors.roleColor}
				roleContentColor={themeColors.roleContentColor}
				roleBulletColor={themeColors.roleBulletColor}
			/>
			{description.map((element, index) => {
				return (
					<StandardParagraph
						color={themeColors.descriptionColor}
						key={index}
						content={element}
					/>
				);
			})}
		</div>
	);
}
