export default function StandardParagraph(props: {
	content: string;
	color: string;
}) {
	return (
		<p
			style={{ color: props.color }}
			className={`standardParagraph font-mono`}
		>
			{props.content}
		</p>
	);
}
