export default function DateHeader(props: { color: string; date: string }) {
	const { color, date } = props;
	return (
		<div className="flexColumnItem" style={{ color }}>
			<div className="delimiter font-mono">DATE</div>
			<div className="dateRange font-mono">{date}</div>
			<div className="delimiter font-mono">DATE</div>
		</div>
	);
}
