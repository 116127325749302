import { type ReactNode } from "react";

interface ContainerProps {
	children: ReactNode;
}

export default function MainContentContainer(props: ContainerProps) {
	const { children } = props;
	return (
		<div id="body" className={`mainContainer font-content`}>
			{children}
		</div>
	);
}
