import { type ReactNode } from "react";
import CardContent from "./CardContent";

export interface CardProps {
	order: number;
	children: ReactNode;
	bgUrl?: string;
	bgColor?: string;
	hasMinHeight?: boolean;
	variant?: string;
}

export default function Card(props: CardProps) {
	const { order, children, bgUrl, bgColor, hasMinHeight } = props;
	const cssClass = order === 1 ? "card_1" : "card";
	return (
		<div
			className={cssClass}
			style={{
				backgroundImage: bgUrl ? `url(${bgUrl})` : "none",
				backgroundSize: bgUrl ? "cover" : "auto auto",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundColor: bgColor || "black",
				order: order,
			}}
		>
			<CardContent hasMinHeight={hasMinHeight} order={order}>
				{children}
			</CardContent>
		</div>
	);
}
