interface TitleProps {
	title: string;
	color: string;
}
export default function Title(props: TitleProps) {
	const { title, color } = props;
	return (
		<div style={{ color }} className={`flexColumnItem font-content title`}>
			{title}
		</div>
	);
}
